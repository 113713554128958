<template>
  <Flex class="ui-search-textfield">
    <TextField
      dense
      :size="size"
      v-model="inputValue"
      :placeholder="$attrs.placeholder"
      :errorMessage="errorMessage"
      :clear="clear"
      :disabled="disabled"
      @clear="$emit('clear')"
    />
    <Button
      icon-only
      icon-name="search"
      :size="size"
      class="ml-2"
      :loading="loading"
      :disabled="disabled"
      @click="$emit('click', inputValue)"
    />
  </Flex>
</template>

<script>
import { computed, defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import TextField from '@/components/ui/TextField.vue';

export default defineComponent({
  name: 'SearchTextField',
  props: {
    modelValue: String,
    size: {
      type: String,
      default: 'm'
    },
    loading: Boolean,
    error: String,
    clear: Boolean,
    disabled: Boolean
  },
  components: {
    Button,
    Flex,
    TextField
  },
  emits: ['click', 'update:modelValue', 'clear'],
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    const errorMessage = computed({
      get: () => props.error ?? ''
    });
    return {
      inputValue,
      errorMessage
    };
  }
});
</script>
<style lang="scss" scoped>
.ui-search-textfield .ui-text-field {
  width: 100%;
}
</style>
