<template>
  <div
    class="grid"
    :class="[alignClass, justifyClass]"
    :style="{ gridTemplateColumns, gridTemplateRows, gridAutoRows, gridGap }"
  >
    <slot />
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
export default defineComponent({
  props: {
    gridTemplateColumns: String,
    gridTemplateRows: String,
    gridGap: String,
    gridAutoRows: String,
    alignStart: Boolean,
    alignCenter: Boolean,
    justifyCenter: Boolean
  },
  setup(props) {
    return {
      alignClass: computed(() => {
        if (props.alignCenter) {
          return 'align center';
        } else if (props.alignStart) {
          return 'align start';
        } else {
          return '';
        }
      }),
      justifyClass: computed(() =>
        props.justifyCenter ? 'justify-center' : undefined
      )
    };
  }
});
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
}
.align {
  &.center {
    align-items: center;
  }
  &.start {
    align-items: flex-start;
  }
}
.justify-center {
  justify-content: center;
}
</style>
