<template>
  <Flex class="mt-">
    <Label class="ml-2 mr-4" block bold size="m" lineHeight="2">
      対象カンパニー
    </Label>
    <Select
      class="company-select"
      dense
      placeholder="選択してください"
      :disabled="companies.length === 0"
      v-model="currentCompanyName"
      :options="companies.map(value => value.name)"
      @change="setCompany"
    />
  </Flex>
  <Flex
    class="mt-4"
    v-if="currentCompany != null"
    justify-space-between
    full-width
  >
    <Box flex="1">
      <Card title="ユーザー">
        <SearchTextField
          placeholder="名前／メールアドレス"
          v-model="inaccessibleAccountsSearchText"
          :loading="isSearchingInAcc"
          @click="
            inAccCheckClear();
            setCompanyInaccessibleAccounts({
              companyId: currentCompanyId,
              workspaceId: workspaceId
            });
          "
        />
        <Grid
          class="mt-4 user-list-title user-list"
          grid-template-columns="46px 1fr 28px"
          grid-auto-rows="31px"
          align-center
        >
          <Box width="15px" height="15px">
            <Checkbox
              v-model="inAccIsCheckedAll"
              :disabled="inaccessibleAccounts.length === 0"
              @change="inAccToggleAll(inaccessibleAccounts)"
            />
          </Box>
          <Label block bold size="m" lineHeight="2">ユーザー</Label>
          <Label block bold size="m" lineHeight="2">権限</Label>
        </Grid>
        <div class="scroll-container">
          <Flex v-if="isLoading" justify-center align-center height="100%">
            <Loading />
          </Flex>
          <template v-else>
            <template
              v-for="inaccAccount in inaccessibleAccounts"
              :key="inaccAccount.id"
            >
              <Hover
                @click="
                  () => {
                    inaccAccount.checked = !inaccAccount.checked;
                    inAccCheck(
                      inaccAccount,
                      inaccAccount.checked,
                      inaccessibleAccounts.length
                    );
                  }
                "
              >
                <Grid
                  grid-template-columns="46px 1fr 28px"
                  grid-auto-rows="67px"
                  align-center
                  class="user-list"
                  :class="{
                    'checked-user': inaccAccount.checked,
                    'left-user': leftUserIds.includes(inaccAccount.id)
                  }"
                >
                  <Box width="15px" height="15px">
                    <Checkbox
                      :value="inaccAccount.id"
                      v-model="inaccAccount.checked"
                    />
                  </Box>
                  <Flex>
                    <AccountIcon :imageUrl="inaccAccount.imageUrl ?? null" />
                    <div class="ml-3">
                      <Typography size="m" bold>{{
                        inaccAccount.name
                      }}</Typography>
                      <Typography class="mt-1">{{
                        inaccAccount.email
                      }}</Typography>
                    </div>
                  </Flex>
                  <Flex justify-end>
                    <PrivilegeIcon :type="inaccAccount.accountPrivilege" />
                  </Flex>
                </Grid>
              </Hover>
            </template>
          </template>
        </div>
      </Card>
    </Box>
    <Box class="mx-6" min-height="100%">
      <Flex flex-direction="column" height="100%" align-center justify-center>
        <IconButton
          v-ripple
          icon-name="arrow-circle-right"
          class="my-4"
          size="xxxxl"
          :disabled="isPostingAuthority || inAccChecked.length === 0"
          @click="onClickleftToRight"
        />
        <IconButton
          v-ripple
          icon-name="arrow-circle-left"
          class="my-4"
          size="xxxxl"
          :disabled="isDeletingAuthority || accChecked.length === 0"
          @click="onClickRightToLeft"
        />
      </Flex>
    </Box>
    <Box flex="1">
      <Card title="アクセス許可">
        <SearchTextField
          placeholder="名前／メールアドレス"
          :loading="isSearchingAcc"
          v-model="accessibleAccountsSearchText"
          @click="
            accCheckClear();
            setCompanyAccessibleAccounts(currentCompanyId);
          "
        />

        <Grid
          class="mt-4 user-list-title user-list"
          grid-template-columns="46px 1fr 28px"
          grid-auto-rows="31px"
          align-center
        >
          <Box width="15px" height="15px">
            <Checkbox
              v-model="accIsCheckedAll"
              :disabled="accessibleAccounts.length === 0"
              @change="accToggleAll(accessibleAccounts)"
            />
          </Box>
          <Label block bold size="m" lineHeight="2">ユーザー</Label>
          <Label block bold size="m" lineHeight="2">権限</Label>
        </Grid>
        <div class="scroll-container">
          <Flex v-if="isLoading" justify-center align-center height="100%">
            <Loading />
          </Flex>
          <template v-else>
            <template
              v-for="accAccount in accessibleAccounts"
              :key="accAccount.id"
            >
              <Hover
                @click="
                  () => {
                    accAccount.checked = !accAccount.checked;
                    accCheck(
                      accAccount,
                      accAccount.checked,
                      accessibleAccounts.length
                    );
                  }
                "
              >
                <Grid
                  grid-template-columns="46px 1fr 28px"
                  grid-auto-rows="67px"
                  align-center
                  class="user-list"
                  :class="{
                    'checked-user': accAccount.checked,
                    'right-user': rightUserIds.includes(accAccount.id)
                  }"
                >
                  <Box width="15px" height="15px">
                    <Checkbox
                      :value="accAccount.id"
                      v-model="accAccount.checked"
                    />
                  </Box>
                  <Flex>
                    <AccountIcon :imageUrl="accAccount.imageUrl ?? null" />
                    <div class="ml-3">
                      <Typography size="m" bold>{{
                        accAccount.name
                      }}</Typography>
                      <Typography class="mt-1">{{
                        accAccount.email
                      }}</Typography>
                    </div>
                  </Flex>
                  <Flex justify-end>
                    <PrivilegeIcon :type="accAccount.accountPrivilege" />
                  </Flex>
                </Grid>
              </Hover>
            </template>
            <template v-for="owner in ownerAccounts" :key="owner.id">
              <Grid
                grid-template-columns="46px 1fr 28px"
                grid-auto-rows="67px"
                align-center
                class="user-list"
              >
                <div />
                <Flex>
                  <AccountIcon :imageUrl="owner.imageUrl ?? null" />
                  <div class="ml-3">
                    <Typography size="m" bold>{{ owner.name }}</Typography>
                    <Typography class="mt-1">{{ owner.email }}</Typography>
                  </div>
                </Flex>
                <Flex justify-end>
                  <PrimaryOwner class="mr-3" />
                  <PrivilegeIcon :type="owner.accountPrivilege" />
                </Flex>
              </Grid>
            </template>
          </template>
        </div>
      </Card>
    </Box>
  </Flex>
  <ClickGuard v-if="isPostingAuthority || isDeletingAuthority" />
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import { PRIVILEGE } from '@/common/constant';

import Box from '@/components/layout/Box.vue';
import Grid from '@/components/layout/Grid.vue';
import Flex from '@/components/layout/Flex.vue';
import AccountIcon from '@/components/ui/AccountIcon.vue';
import PrivilegeIcon from '@/components/ui/PrivilegeIcon.vue';
import Card from '@/components/ui/Card.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import ClickGuard from '@/components/ui/ClickGuard.vue';
import Hover from '@/components/ui/Hover.vue';
import IconButton from '@/components/ui/IconButton.vue';
import Label from '@/components/ui/Label.vue';
import Loading from '@/components/ui/Loading.vue';
import PrimaryOwner from '@/components/ui/PrimaryOwner.vue';
import SearchTextField from '@/components/ui/SearchTextField.vue';
import Typography from '@/components/ui/Typography.vue';
import Select from '@/components/ui/Select.vue';
import useCheckBox from '@/composables/checkbox';
import {
  addAuthority,
  deleteAuthority,
  isDeletingAuthority,
  isPostingAuthority,
  useCompanies,
  useCompanyAccessibleAccounts,
  useCompanyInaccessibleAccounts,
  useMovedUser
} from '@/composables/workspace';
import { useRoute } from 'vue-router';
import useMoveItem from '@/composables/moveItem';
import { toast } from '@/components/ui/Toast';

export default defineComponent({
  name: 'WorkspaceAccess',
  components: {
    AccountIcon,
    PrivilegeIcon,
    Box,
    Card,
    Checkbox,
    ClickGuard,
    Flex,
    Grid,
    Hover,
    IconButton,
    Label,
    Loading,
    PrimaryOwner,
    Typography,
    SearchTextField,
    Select
  },
  setup() {
    const {
      params: { workspaceId }
    } = useRoute();

    const {
      checkHandler: inAccCheck,
      checkAll: inAccCheckAll,
      checked: inAccChecked,
      isCheckedAll: inAccIsCheckedAll,
      uncheckAll: inAccUncheckAll,
      toggleAll: inAccToggleAll
    } = useCheckBox();

    const {
      checkHandler: accCheck,
      checkAll: accCheckAll,
      checked: accChecked,
      isCheckedAll: accIsCheckedAll,
      uncheckAll: accUncheckAll,
      toggleAll: accToggleAll
    } = useCheckBox();

    const {
      companies,
      currentCompany,
      currentCompanyName,
      currentCompanyId,
      setCompany,
      fetchCompanies
    } = useCompanies(workspaceId.toString());

    const {
      accessibleAccounts,
      accessibleAccountsSearchText,
      ownerAccounts,
      setCompanyAccessibleAccounts,
      isSearchingAcc
    } = useCompanyAccessibleAccounts();

    const {
      inaccessibleAccounts,
      inaccessibleAccountsSearchText,
      setCompanyInaccessibleAccounts,
      isSearchingInAcc
    } = useCompanyInaccessibleAccounts();

    const accCheckClear = () => {
      accUncheckAll(accessibleAccounts.value);
    };
    const inAccCheckClear = () => {
      inAccUncheckAll(inaccessibleAccounts.value);
    };

    const isLoading = ref(false);
    const selectCompanyEffect = async () => {
      accCheckClear();
      inAccCheckClear();
      const company = currentCompany.value;
      if (company !== null && company.id !== null) {
        const companyId = company.id;
        isLoading.value = true;
        await Promise.all([
          setCompanyAccessibleAccounts(companyId),
          setCompanyInaccessibleAccounts({ companyId, workspaceId })
        ]);
        isLoading.value = false;
      }
    };
    watch(currentCompany, selectCompanyEffect);

    fetchCompanies();

    const { leftToRight, rightToLeft } = useMoveItem(
      inaccessibleAccounts,
      accessibleAccounts
    );

    const {
      rightUserIds,
      leftUserIds,
      setRightUsers,
      setLeftUsers
    } = useMovedUser();

    const onClickleftToRight = async () => {
      const res = await addAuthority({
        companyId: currentCompanyId.value,
        accounts: inAccChecked.value
      });
      if (res.status === 201) {
        leftToRight(inAccChecked.value);
        setRightUsers(inAccChecked.value.map(user => user.id));
        accUncheckAll(accessibleAccounts.value);
        inAccUncheckAll(inaccessibleAccounts.value);
      } else {
        toast({
          title: '失敗しました',
          variant: 'error'
        });
      }
    };

    const onClickRightToLeft = async () => {
      const res = await deleteAuthority({
        companyId: currentCompanyId.value,
        accounts: accChecked.value
      });
      if (res.status === 204) {
        rightToLeft(accChecked.value);
        setLeftUsers(accChecked.value.map(user => user.id));
        accUncheckAll(accessibleAccounts.value);
        inAccUncheckAll(inaccessibleAccounts.value);
      } else {
        toast({
          title: '失敗しました',
          variant: 'error'
        });
      }
    };

    return {
      ownerId: PRIVILEGE.owner,
      isPostingAuthority,
      isDeletingAuthority,
      companies,
      currentCompany,
      currentCompanyName,
      currentCompanyId,
      setCompany,
      accessibleAccounts,
      accessibleAccountsSearchText,
      inaccessibleAccounts,
      inaccessibleAccountsSearchText,
      ownerAccounts,
      inAccCheck,
      inAccCheckAll,
      inAccChecked,
      inAccIsCheckedAll,
      inAccUncheckAll,
      inAccToggleAll,
      accCheck,
      accCheckAll,
      accChecked,
      accIsCheckedAll,
      accUncheckAll,
      accToggleAll,
      accCheckClear,
      inAccCheckClear,
      workspaceId,
      setCompanyAccessibleAccounts,
      setCompanyInaccessibleAccounts,
      rightUserIds,
      leftUserIds,
      setLeftUsers,
      isLoading,
      onClickleftToRight,
      onClickRightToLeft,
      isSearchingInAcc,
      isSearchingAcc
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';
@import '@/styles/layout';
.company-select {
  width: 250px;
}
.user-list-title {
  background-color: var(--dark-5-color);
}
.user-list {
  padding-left: $spacing-base * 2;
  padding-right: $spacing-base * 4;
  &:not(:last-child):not(.user-list-title) {
    border-bottom: 1px solid var(--primary-light-color);
  }
  .account-icon {
    min-width: 32px;
  }
}
.checked-user {
  background-color: var(--primary-light-color);
  .ui-typography {
    color: var(--primary-color);
  }
}
.right-user:not(.checked-user) {
  background-color: var(--tertiary-light-color);
}
.left-user:not(.checked-user) {
  background-color: var(--primary-pale-color);
}
.scroll-container {
  height: calc(100vh - 477px);
  overflow-y: scroll;
}
</style>
