<template>
  <PrimaryOwnerSvg />
</template>
<script>
import { defineComponent } from 'vue';
import PrimaryOwnerSvg from '@/assets/images/primary-owner.svg?inline';

export default defineComponent({
  name: 'PrimaryOwner',
  components: { PrimaryOwnerSvg }
});
</script>
