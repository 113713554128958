<script>
import { defineComponent, h } from 'vue';

/**
 * Hover<br />
 * ----------------------<br />
 * クリック, チェック, 選択 などのアクション領域を示すための hoverスタイルを定義
 * hoverのパターンが増えたら、propsでtypeなどを追加する想定
 *
 * refs: https://www.figma.com/file/ShKIFZxFgmOYm6Ef1U6Tk0/V3-DesignSystem?node-id=5421%3A114703
 */

export default defineComponent({
  render: ({ $slots, tag, display }) => {
    return h(
      tag,
      {
        class: 'ui-hover',
        style: {
          display
        }
      },
      $slots.default()
    );
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    display: {
      type: String,
      default: 'block'
    }
  }
});
</script>
<style lang="scss" scoped>
.ui-hover {
  transition-property: background-color;
  transition-duration: var(--transition-duration);
  transition-timing-function: var(--transition-timing-function);
  &:hover {
    background-color: var(--background-hover-color);
    cursor: pointer;
  }
  ::selection {
    background-color: transparent;
  }
}
</style>
