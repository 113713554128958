import { Ref } from 'vue';

interface moveItemReturn<T> {
  leftToRight: (items: Array<T>) => void;
  rightToLeft: (items: Array<T>) => void;
}

const useMoveItem = <T extends { checked: boolean }>(
  leftItems: Ref<Array<T>>,
  rightItems: Ref<Array<T>>
): moveItemReturn<T> => {
  const resetCheckbox = () => {
    leftItems.value.map(value => (value.checked = false));
    rightItems.value.map(value => (value.checked = false));
  };

  const leftToRight = (items: Array<T>) => {
    leftItems.value = leftItems.value.filter(val => {
      return !items.some(item => item === val);
    });
    rightItems.value = [...rightItems.value, ...items];
    resetCheckbox();
  };

  const rightToLeft = async (items: Array<T>) => {
    rightItems.value = rightItems.value.filter(val => {
      return !items.some(item => item === val);
    });
    leftItems.value = [...leftItems.value, ...items];
    resetCheckbox();
  };

  return {
    leftToRight,
    rightToLeft
  };
};

export default useMoveItem;
