<template>
  <Icon :name="iconName" :sides="iconSides || '20'" />
</template>
<script>
import { defineComponent, computed } from 'vue';
import { PRIVILEGE } from '@/common/constant';
import Icon from '@/components/ui/Icon.vue';

export default defineComponent({
  name: 'PrivilegeIcon',
  components: { Icon },
  props: {
    type: String,
    iconSides: String
  },
  setup(props) {
    return {
      iconName: computed(() => {
        switch (props.type) {
          case PRIVILEGE.primary:
            return 'privilege-primary';
          case PRIVILEGE.owner:
            return 'privilege-owner';
          case PRIVILEGE.admin:
            return 'privilege-admin';
          case PRIVILEGE.member:
            return 'privilege-member';
          case PRIVILEGE.restricted:
            return 'privilege-restricted';
          case PRIVILEGE.freeOwner:
            return 'privilege-primary';
          case PRIVILEGE.freeMember:
            return 'privilege-member';
          default:
            return '';
        }
      })
    };
  }
});
</script>
